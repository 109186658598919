export const educations = [
  {
    title: "Graduation in Telecomunication Engineering",
    date: "01/2003-05/2005",
    school: "TorVergata University, Rome - Italy",
    thesis:
      "Analysis and implementation of extension to the SIP protocol to support QoS on IP networks",
    degree: "110/110 cum lode",
    icon: "fa-graduation-cap",
  },
  {
    title: "Bachelor in Telecomunication Engineering",
    date: "09/1998-01/2003",
    school: "TorVergata University, Rome - Italy",
    thesis: "802.11 Security Issues",
    degree: "110/110 cum lode",
    icon: "fa-graduation-cap",
  },
  {
    title: "Industrail Technical Expert",
    date: "09/1993-06/1998",
    school: "ITIS Giovanni XXIII, Rome - Italy",
    thesis: "-",
    degree: "52/60",
    icon: "fa-school",
  },
];
