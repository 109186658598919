export const menuVoice = [
  {
    title: "About Me",
    url: "about-me",
  },
  {
    title: "Work Experiences",
    url: "work-experiences",
  },
  {
    title: "Educations",
    url: "educations",
  },
  {
    title: "Skills",
    url: "skills",
  },
  {
    title: "Contacts",
    url: "contact-me",
  },
];
