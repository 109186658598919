import React from "react";
import { FadeIn } from "react-slide-fade-in";

import { educations } from "../../../config/educations";

import Title from "../../base/Title";
import Card from "../../base/Card";

const Educations = () => {
  return (
    <FadeIn
      from="bottom"
      positionOffset={0}
      triggerOffset={0}
      delayInMilliseconds={1400}
    >
      <section
        id="educations"
        className="page-with-nav d-flex flex-column justify-content-center align-items-center"
      >
        <Title>Educations</Title>
        <div className="row mt-5 text-center">
          {educations.map(
            ({ icon, title, date, school, thesis, degree }, _index) => (
              <div
                key={`education-index-${_index}`}
                className="col-xs-12 col-lg-4 mb-5 justify-content-center"
              >
                <Card
                  icon={icon}
                  title={title}
                  school={school}
                  degree={degree}
                  thesis={thesis}
                  date={date}
                />
              </div>
            )
          )}
        </div>
      </section>
    </FadeIn>
  );
};

export default Educations;
