import React from "react";

const Card = ({ icon, title, school, date, thesis = null, degree }) => {
  return (
    <div className="card mx-5" style={{width:'18rem'}}>
      <div className="text-center mt-2">
        <span className="fa-stack fa-2x">
          <i className="fa-solid fa-circle fa-stack-2x"></i>
          <i className={`fa-solid ${icon} fa-stack-1x fa-inverse`}></i>
        </span>
      </div>

      <div className="card-body" style={{ height: "6rem" }}>
        <h5 className="card-title text-center">{title}</h5>
      </div>
        <ul className="list-group list-group-flush">
          <li style={{ fontSize: 14 }} className="list-group-item">
            <span>
              <b>School: </b>
            </span>
            <span>{school}</span>
          </li>
          <li style={{ fontSize: 14 }} className="list-group-item">
            <span>
              <b>Date: </b>
            </span>
            <span>{date}</span>
          </li>
          <li style={{ fontSize: 14 }} className="list-group-item">
            <span>
              <b>Degree: </b>
            </span>
            <span>{degree}</span>
          </li>
          <li style={{ fontSize: 14 }} className="list-group-item">
            <span>
              <b>Thesis: </b>
            </span>
            <span>{thesis}</span>
          </li>
        </ul>
      </div>
  );
};

export default Card;
