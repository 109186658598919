import React from "react";
import { FadeIn } from "react-slide-fade-in";

import Social from "../../base/Social";
import Title from "../../base/Title";

const Contacts = () => {
  return (
    <FadeIn
      from="bottom"
      positionOffset={0}
      triggerOffset={0}
      delayInMilliseconds={800}
    >
      <section
        id="contact-me"
        className="page-with-nav d-flex flex-column justify-content-center align-items-center"
      >
        <div className="mb-5">
          <Title color={"text-dark"}>Contacts</Title>
        </div>
        <div className="row justify-content-center mb-4">
          <div className="col-xs-12 col-lg-8">
            <p className="text-center mx-3">
              Hi, I'm always interested in talking about new ideas and projects,
              especially in the crypto/blockchain/web3 world.
              <br />I am available for consultancy or freelance collaborations
              <br />
              Here are some ways to get in touch...
            </p>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col">
            <a
              href="mailto:francesco.primerano79@gmail.com"
              className="btn btn-lg text-capitalize btn-outline-primary py-3 px-4"
              style={{ fontSize: 16 }}
            >
              get in touch<i className="mx-1 fa-regular fa-paper-plane"></i>
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Social color="text-dark" />
          </div>
        </div>
      </section>
    </FadeIn>
  );
};

export default Contacts;
