import React from "react";
import { TypeAnimation } from "react-type-animation";
import { FadeIn } from "react-slide-fade-in";

import { welcomePage } from "../../../config/welcomePage";

import Social from "../../base/Social";

const Index = () => {
  return (
    <section className="bg-dark d-flex justify-content-center flex-column page">
      <div className="container">
        <FadeIn
          from="bottom"
          positionOffset={0}
          triggerOffset={0}
          delayInMilliseconds={800}
        >
          <h2 className="text-light text-capitalize display-3">
            {welcomePage.hello},
          </h2>
        </FadeIn>

        <FadeIn
          from="bottom"
          positionOffset={0}
          triggerOffset={0}
          delayInMilliseconds={1000}
        >
          <h2 className="text-light text-capitalize display-3">
            <b>
              {welcomePage.name}
              <span className="text-warning"> {welcomePage.surname}</span>.
            </b>
          </h2>
        </FadeIn>
        <FadeIn
          from="bottom"
          positionOffset={0}
          triggerOffset={0}
          delayInMilliseconds={1200}
        >
          <p className="text-light text-capitalize display-6 mt-5">
            i am into
            <TypeAnimation
              sequence={[
                welcomePage.engineer,
                1500,
                welcomePage.senior_full_stack,
                1500,
                welcomePage.blockchain_believer,
                1500,
                welcomePage.crypto_lover,
                1500,
              ]}
              wrapper="span"
              className="ms-2 text-warning text-capitalize"
              cursor={true}
              repeat={Infinity}
            />
          </p>
        </FadeIn>
      </div>
      <FadeIn
        from="bottom"
        positionOffset={0}
        triggerOffset={0}
        delayInMilliseconds={1400}
      >
        <div className="container mt-5">
          <div className="row">
            <div className="col">
              <a
                href="#about-me"
                className="btn btn-lg text-capitalize btn-outline-primary py-3 px-4"
                style={{ color: "white", fontSize: 16 }}
              >
                {welcomePage.about_me}
                <i className="mx-1 fa-regular fa-user"></i>
              </a>
            </div>
          </div>
        </div>
      </FadeIn>

      <FadeIn
        from="bottom"
        positionOffset={0}
        triggerOffset={0}
        delayInMilliseconds={1600}
      >
        <div className="container mt-5">
          <Social />
        </div>
      </FadeIn>
    </section>
  );
};

export default Index;
