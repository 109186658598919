import React from "react";
import { Link } from "react-scroll";

import { menuVoice } from "../../../config/menuVoice";
import fpLogo from "../../../assets/img/logo_small.png";

const Navbar = () => {
  return (
    <React.Fragment>
      <nav
        className="navbar navbar-expand-lg navbar-dark fixed-top sticky-top"
        style={{ backgroundColor: "#2b2020" }}
      >
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img
              className="mx-3"
              src={fpLogo}
              alt="Francesco Primerano"
              width="30"
            />

            <b>Francesco Primerano </b>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent"
            style={{flexGrow:'unset'}}
          >
            <ul className="navbar-nav mr-5 ml-auto">
              {menuVoice.map(({ title, url }, _index) => {
                return (
                  <li key={`menu-index-${_index}`} className="nav-item">
                    <Link
                      style={{ cursor: "pointer" }}
                      activeClass="active"
                      smooth
                      spy
                      className={`nav-link`}
                      to={url}
                    >
                      {title}
                      <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Navbar;
