export const social = [
  {
    title: "Linkedin",
    icon: "mx-2 fa-brands fa-linkedin fa-2xl",
    url: "https://www.linkedin.com/in/francesco-primerano-b9281186/",
  },
  {
    title: "GitHub",
    icon: "mx-2 fa-brands fa-github fa-2xl",
    url: "https://github.com/pf55351",
  },
  {
    title: "Twitter",
    icon: "mx-2 fa-brands fa-twitter fa-2xl",
    url: "https://twitter.com/francescoilguru",
  },

  {
    title: "Telegram",
    icon: "mx-2 fa-brands fa-telegram fa-2xl",
    url: "https://telegram.me/pf55351",
  },
  {
    title: "Google",
    icon: "mx-2 fa-brands fa-google fa-2xl",
    url: "mailto:francesco.primerano79@gmail.com",
  },
  {
    title: "Skype",
    icon: "mx-2 fa-brands fa-skype fa-2xl",
    url: "skype:francescoprimerano",
  },
];
