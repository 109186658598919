import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import Maintenence from "./components/pages/Maintenence";
import Index from "./components/pages/Index";

const App = () => {
  const mode = process.env.REACT_APP_MODE;
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={mode === "online" ? <Index /> : <Maintenence />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
