import React from "react";

import { social } from "../../../config/social";

const Social = ({ color = "text-light" }) => {
  return (
    <React.Fragment>
      {social.map(({ title, icon, url }, _index) => (
        <a
          href={url}
          key={`sociale-index-${_index}`}
          title={title}
          className={color}
          target="blank"
        >
          <i className={icon}></i>
        </a>
      ))}
    </React.Fragment>
  );
};

export default Social;
