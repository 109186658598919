import React from "react";
import { FadeIn } from "react-slide-fade-in";

import Title from "../../base/Title";

import me from "../../../assets/img/me.jpg";
import resume from "../../../assets/doc/resume.pdf";

const AboutMe = () => {
  return (
    <section
      id="about-me"
      className="page-with-nav d-flex flex-column justify-content-center align-items-center"
    >
      <FadeIn
        from="bottom"
        positionOffset={0}
        triggerOffset={0}
        delayInMilliseconds={800}
      >
        <Title>About Me</Title>
      </FadeIn>

      <div className="row mt-5 justify-content-lg-center">
        <div className="col-xs-12 col-lg-4 my-auto">
          <FadeIn
            from="left"
            positionOffset={400}
            triggerOffset={200}
            delayInMilliseconds={400}
          >
            <img
              className="rounded mx-auto d-block img-fluid rounded filterImg"
              src={me}
              alt="me"
              width={300}
              height={200}
            />
          </FadeIn>
        </div>

        <div className="col-xs-12 col-lg-5 my-5">
          <FadeIn
            from="right"
            positionOffset={400}
            triggerOffset={200}
            delayInMilliseconds={400}
          >
            <div className="container-fluid px-5">
              <p>
                I am a telecommunications engineer but I have always worked in
                the IT world. Immediately after graduation I worked as a J2ME
                developer at the{" "}
                <a
                  className="link"
                  href="http://netgroup.uniroma2.it/"
                  target="_blank"
                  rel="noreferrer"
                >
                  University of Rome TorVergata
                </a>{" "}
                in European projects.
              </p>
              <p>
                After this experience I worked at the
                <a
                  className="link"
                  href="https://www.acapo.it/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Capodarco
                </a>{" "}
                cooperative as administrator of the VoIP system for managing
                calls of the regional ReCup service. Subsequently I worked as a
                full-stack developer at Blu Sistemi, developing web portals and
                hybrid mobile applications for gaming concessionaires.
              </p>
              <p>
                At the same time I continued my interest in blockchain
                technology and cryptocurrencies and this led me to work, again
                as a developer for{" "}
                <a
                  href="https://eidoo.app"
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Eidoo
                </a>
                , developing part of the new wallet and other systems and
                functions always linked to the world of cryptocurrencies and
                web3.
              </p>
              <a
                href={resume}
                download="Francesco_Primerano_Resume"
                className="btn text-capitalize btn-outline-primary"
              >
                Resume
                <i className="mx-1 fa-regular fa-file"></i>
              </a>
            </div>
          </FadeIn>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
