import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import Title from "../../base/Title";

import "react-vertical-timeline-component/style.min.css";
import resume from "../../../assets/doc/resume.pdf";
import { workExperiences } from "../../../config/workExperiences";

const WorkExperinces = () => {
  return (
    <section
      id="work-experiences"
      className="page-with-nav d-flex flex-column justify-content-center align-items-center bg-light"
    >
      <Title>Work Experiences</Title>
      <div className="container">
        <div className="row justify-content-center my-5">
          <div className="col-12">
            <VerticalTimeline>
              {workExperiences.map(({ date, role, desc, nation }, _index) => (
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date={date}
                  iconStyle={{ background: "#00c4a7", color: "#fff" }}
                  key={`experiences-index-${_index}`}
                >
                  <h5 className="vertical-timeline-element-title">{role}</h5>
                  <h6 className="vertical-timeline-element-subtitle">
                    {nation}
                  </h6>
                  <p>
                    <ul>
                      {desc.map((_el, _index) => (
                        <li>{_el}</li>
                      ))}
                    </ul>
                  </p>
                </VerticalTimelineElement>
              ))}
            </VerticalTimeline>
          </div>
        </div>
        <div className="mb-5 d-flex align-items-center justify-content-center">
          <a
            href={resume}
            download="Francesco_Primerano_Resume"
            className="btn text-capitalize btn-outline-primary"
          >
            Resume
            <i className="mx-1 fa-regular fa-file"></i>
          </a>
        </div>
      </div>
    </section>
  );
};

export default WorkExperinces;
