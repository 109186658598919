export const skills = [
  {
    name: "ReactJS",
    icon: "https://img.icons8.com/external-tal-revivo-color-tal-revivo/48/000000/external-react-a-javascript-library-for-building-user-interfaces-logo-color-tal-revivo.png",
  },
  {
    name: "ReactNative",
    icon: "https://img.icons8.com/external-tal-revivo-color-tal-revivo/48/000000/external-react-a-javascript-library-for-building-user-interfaces-logo-color-tal-revivo.png",
  },
  {
    name: "ExpressJS",
    icon: "https://img.icons8.com/fluency/48/000000/node-js.png",
  },
  {
    name: "NodeJS",
    icon: "https://img.icons8.com/color/48/000000/nodejs.png",
  },
  {
    name: "Redux",
    icon: "https://img.icons8.com/color/48/000000/redux.png",
  },

  {
    name: "Bootstrap",
    icon: "https://img.icons8.com/color/48/000000/bootstrap.png",
  },

  {
    name: "HTML5",
    icon: "https://img.icons8.com/color/48/000000/html-5--v1.png",
  },
  {
    name: "CSS3",
    icon: "https://img.icons8.com/color/48/000000/css3.png",
  },
  {
    name: "JavaScript",
    icon: "https://img.icons8.com/color/48/000000/javascript--v1.png",
  },

  {
    name: "PHP",
    icon: "https://img.icons8.com/offices/48/000000/php-logo.png",
  },

  {
    name: "MongoDB",
    icon: "https://img.icons8.com/color/48/000000/mongodb.png",
  },
  {
    name: "MySQL",
    icon: "https://img.icons8.com/color/48/000000/mysql-logo.png",
  },
  {
    name: "PostgreSQL",
    icon: "https://img.icons8.com/color/48/000000/postgreesql.png",
  },
  {
    name: "AWS",
    icon: "https://img.icons8.com/color/48/000000/amazon-web-services.png",
  },
  {
    name: "Heroku",
    icon: "https://img.icons8.com/color/48/000000/heroku.png",
  },
  {
    name: "Netlify",
    icon: "https://img.icons8.com/external-tal-revivo-shadow-tal-revivo/48/000000/external-netlify-a-cloud-computing-company-that-offers-hosting-and-serverless-backend-services-for-static-websites-logo-shadow-tal-revivo.png",
  },
  {
    name: "DigitalOcean",
    icon: "https://img.icons8.com/ios-filled/48/0080FF/digitalocean.png",
  },
  {
    name: "jQuery",
    icon: "https://img.icons8.com/ios-filled/48/1169ae/jquery.png",
  },
  {
    name: "Git VCS",
    icon: "https://img.icons8.com/color/48/000000/git.png",
  },
  {
    name: "GitHub",
    icon: "https://img.icons8.com/glyph-neue/48/ffffff/github.png",
  },
];