import React from "react";
import Title from "../../base/Title";
import { FadeIn } from "react-slide-fade-in";

import { skills } from "../../../config/skills";

const Skills = () => {
  return (
    <FadeIn
      from="bottom"
      positionOffset={0}
      triggerOffset={0}
      delayInMilliseconds={800}
    >
      <section
        id="skills"
        className="page-with-nav d-flex flex-column justify-content-center align-items-center bg-light"
      >
        <Title>Skills</Title>
        <div className="container">
          <div className="row mt-5 justify-content-center ">
            <div className="col-7 bg-secondary">
              <div className="row my-4 text-center justify-content-center">
                {skills.map(({ name, icon }, _index) => (
                  <div
                    className="col-lg-2 px-2 py-2 mx-2 my-2 bg-dark"
                    key={`skill-index-${_index}`}
                  >
                    <img src={icon} alt="" />
                    <div>
                      <span className="text-white">{name}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </FadeIn>
  );
};

export default Skills;
