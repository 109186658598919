import React from "react";

import { useScrollToLocation } from "../../../hook/useScrollToLocation";

import Navbar from "../../base/Navbar";
import AboutMe from "../AboutMe";
import WorkExperinces from "../WorkExperiences";
import Educations from "../Educations";
import Skills from "../Skills";
import Contact from "../Contacts";
import Welcome from "../Welcome";

const Index = () => {
  useScrollToLocation();
  return (
    <>
      <Welcome />
      <Navbar />
      <AboutMe />
      <WorkExperinces />
      <Educations />
      <Skills />
      <Contact />
    </>
  );
};

export default Index;
