export const workExperiences = [
  {
    date: "09/2021-11/2022",
    role: "Full Stack Dev @Eidoo Tech",
    nation: "London, UK",
    desc: [
      "Development new Wallet App EidooV2 (ReactNative)",
      "Development dashboard for Kyc EidooID (React)",
      "Development dashborad admin for backoffice project EidooID (React)",
      "Development dashboard for EidooCard",
      "Development AWS Lambda in Node (Serverless Framework) for EidooCard",
      "Development AWS Lambda in Node (Serverless Framework) for EidooIdAminApi/EidooIdApi",
    ],
  },
  {
    date: "11/2018-08/2021",
    role: "Full Stack Dev @TCH Sistemi",
    nation: "Rome, IT",
    desc: [
      "Analysis and development of portal for gaming concessionaires (APO)",
      "Analysis and development of mobile app for gaming concessionaires (Cordova)",
      "Development web app for Lottomatica (Angular)",
      "Development web app Admiral Pay (React)",
      "Development mobile app Admiral Pay Wallet (React Native)",
    ],
  },
  {
    date: "12/2007-11/2018",
    role: "VoIP Admin | Developer @Cooperativa Capodarco",
    nation: "Rome, IT",
    desc: [
      "Co-Administrator and developer of PBX VoIP system (Asterisk/MySQL/BashScripting)",
      "Development of client VoIP/WebRTC",
      "Development of client VoIP/J2SE",
    ],
  },
];
