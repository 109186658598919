export const welcomePage = {
  hello: "Hi there",
  name: "I'm Francesco",
  surname: "Primerano",
  i_am_into: "i am into",
  engineer: "Engineer",
  senior_full_stack: "Senior Full Stack",
  blockchain_believer: "Blockchain believer",
  crypto_lover: "Crypto Lover",
  about_me:"About me"
};
